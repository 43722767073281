// Data sevice
import axios from 'axios'
import authHeader from './auth-header'

const API_URL = process.env.VUE_APP_API_URL;

class NotificationService {
    getAllNotifications() {
        return axios.get(API_URL + 'notifications', { headers: authHeader() })
    }
    getAllUserNotifications(user) {
        return axios.get(API_URL + 'notifications-for-user/' + user, { headers: authHeader() })
    }
    getCategories() {
        return axios.get(API_URL + 'notification-groups', { headers: authHeader() })
    }
    deleteNotification(resource) {
        return axios.delete(API_URL + 'notifications/' + resource.id, { headers: authHeader() })
    }
    updateNotification(resource) {
        return axios.put(API_URL + 'notifications/' + resource.id, resource, { headers: authHeader() })
    }
    updateUserNotificationStatus(resource) {
        return axios.post(API_URL + 'user-notification-status/' + resource.id, resource, { headers: authHeader() })
    }
    storeNotification(resource) {
        return axios.post(API_URL + 'notifications' , resource, { headers: authHeader() })
    }
}

export default new NotificationService()
